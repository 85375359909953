<template>
  <div>
    <Collapse v-model="bodyVisible"  :index="index" :confirmAdd="confirmAdd "
    :cancelAdd="cancelAdd">
      <template v-slot:header>
        <slot></slot>
        <button type="button"
                class="btn btn-fav"
                v-if="bodyVisible"
                @click.stop="showFavoritesModal"
                :disabled="isLoading && favoritesModalVisible"
                style="flex-shrink: 1; order: 1;"
        >
          <loading/>
          <img v-if="!isLoading"
               src="@/assets/images/icons/heart.svg"
               alt="hart-favpurites"
          />
          <span>Favourites</span>
        </button>
      </template>
      <template v-slot:default>
        <form @submit.prevent="submitPickup" v-if="!isDropoff && isBusinessUser" class="py-2">
          <select v-model="selectedPickup" @change="pickupUpdated" class="form-select mb-3">
            <option selected hidden>Select Pickup</option>
            <option v-for="pickup in pickups" :key="pickup.id" :value="pickup"> {{ pickup.location }}</option>
            <option selected value="Other">Other</option>
          </select>
          <TelInput 
           v-if="!['Select Pickup', 'Other'].includes(this.selectedPickup)"
              class="col-12"
              :form="form"
              v-modal="form.data.phone"
              :vuelidate="v$"
              field="phone"
              :auto-country="autoPhoneCountry"
          />
          <Input
          v-if="!['Select Pickup', 'Other'].includes(this.selectedPickup)"
              class="col-12"
              :form="form"
               v-modal="form.data.description"
              :vuelidate="v$"
              name="description"
              field="description"
              placeholder="Description"
          />

          <button
              v-if="selectedPickup !== 'Other'"
              type="submit"
              class="btn btn-primary btn-primary-peyk mt-4"
              :disabled="selectedPickup && typeof selectedPickup !== 'object' || v$.$invalid"
              >
            Confirm
          </button>
        </form>
        <form v-if="!isBusinessUser || selectedPickup == 'Other' || isDropoff" class="row" @submit.prevent="submit">
          <hr/>
          <div class="col-12">
            <p style="color: #457c9f">
              To make sure the driver arrives correctly, please fill up the order description appropriately.
            </p>
          </div>
          <Input
              class="col-12"
              :form="form"
              :vuelidate="v$"
              :name="name"
              field="name"
          />
          <TelInput
              class="col-12"
              :form="form"
              :vuelidate="v$"
              field="phone"
              :auto-country="autoPhoneCountry"
          />
          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header accordion-header-blue-board" id="headingBluePlate">
                <button class="accordion-button collapsed d-flex justify-content-between align-items-center"
                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseBluePlate"
                        aria-expanded="false" aria-controls="collapseBluePlate">
                  <span><img src="@/assets/images/icons/blue-plate.png" class="blue-plate-img" alt="blue plate">Search by National Address(Blue Plate)</span>
                  <img src="@/assets/images/icons/vuesax-bold-arrow-down-1.png" style="width: 18px;" alt="">
                </button>
              </h2>
              <div id="collapseBluePlate" class="accordion-collapse collapse" aria-labelledby="headingBluePlate"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body p-2">
                  <div class="zone-line">
                    <Input
                        class="col-12 blue-plate-input"
                        field="building_no"
                        placeholder="Building No."
                        :form="form"
                        :vuelidate="v$"
                    />
                    <div class="d-flex justify-content-between align-items-center custom-gap">
                      <Input
                          class="blue-plate-input"
                          field="zone_no"
                          placeholder="Zone No."
                          :form="form"
                          :vuelidate="v$"
                      />
                      <Input
                          class="blue-plate-input"
                          field="street_no"
                          placeholder="Street No."
                          :form="form"
                          :vuelidate="v$"
                      />
                    </div>
                    <div class="d-grid">
                      <button class="btn  btn-find mt-2" @click.prevent="findMap">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header accordion-header-google" id="headingGoogleMapSearch">
                <button class="accordion-button collapsed d-flex justify-content-between align-items-center"
                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseGoogleMapSearch"
                        aria-expanded="false" aria-controls="collapseGoogleMapSearch">
                  <span><img src="@/assets/images/icons/google-map.png" class="google-map-icon" alt="google map">Search by Google Address</span>
                  <img src="@/assets/images/icons/vuesax-bold-arrow-down-1.png" style="width: 18px;" alt="">
                </button>
              </h2>
              <div id="collapseGoogleMapSearch" class="accordion-collapse collapse "
                   aria-labelledby="headingGoogleMapSearch" data-bs-parent="#accordionExample">
                <div class="accordion-body p-2">
                  <SearchAddress
                      class="col-12 blue-plate-input"
                      field="address"
                      :form="form"
                      :vuelidate="v$"
                      :caption="searchCaption"
                      @locationChanged="updateLocation"
                  />
                  <div class="input-group mb-3">
                    <map-link
                        class="blue-plate-input"
                        :UrlInput="valueinput"
                        @UrlInput="getInputValue"
                    />
                    <button class="input-group-text custom-border" @click.prevent="googleUrl">
                      <img src="@/assets/images/icons/vuesax-linear-search-normal.png" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- remove Pay option 12-10-24 -->
          <!-- <Input
              class="col-6 pyk-collect"
              :form="form"
              :vuelidate="v$"
              name="collect"
              field="collect"
              placeholder="Pay"
          /> -->
          <Input
              class="col-12 pyk-deposit"
              :form="form"
              :vuelidate="v$"
              name="deposit"
              field="deposit"
              placeholder="COD Amount"
          />
          <Input
              class="col-12"
              :form="form"
              :vuelidate="v$"
              name="description"
              field="description"
              placeholder="Description"
          />
          <SubmitButton class="d-grid gap-2 mt-2 btn-confirm"
                        :form="form"
                        :vuelidate="v$"
          >Confirm
          </SubmitButton>
        </form>
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="false">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Confirm to delete the additional dropoff.
              </div>
              <div class="modal-footer">
                <SubmitButton ref="btn_confirm_id" class="d-grid gap-2 mt-2 btn-confirm"
                              :form="form"
                              :vuelidate="v$"
                              :disabled="false"
                >Confirm
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Collapse>
    <div v-if="isConfirmAdd[index] === false" class="text-danger">
      <span>Please confirm the address</span>
    </div>
    <Fade>
      <div class="custom-modal-background" @click.self="favoritesModalVisible = false;" v-if="favoritesModalVisible">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Select Your Favourite</span>
            <button @click="$router.push({name: 'AddFavorite'})">Add new</button>
          </div>
          <div class="modal-body">
            <div class="favorite-item"
                 v-for="favorite in favorites"
                 :key="favorite.id"
                 :class="{ 'selected': favorite.id === selectedFavorite.id }"
                 @click.prevent="selectedFavorite = favorite"
            >
              <img src="@/assets/images/icons/Home.png" alt="home" v-if="favorite.type === 'Home'">
              <img src="@/assets/images/icons/work-2.png" alt="work" v-else-if="favorite.type === 'Work'">
              <img src="@/assets/images/icons/location.png" alt="location" v-else>
              <span class="ms-2">{{ favorite.name }}</span>
            </div>
            <div class="load-more" @click.prevent="changePage(paginateInfo.current_page + 1)" v-if="showLoadMore"><span
                class="icon"><i class="bi bi-plus"></i></span>Load More
            </div>
          </div>
          <div class="modal-footer">
            <button
                @click="submitFavorite"
                :disabled="! selectedFavorite.id"
            >Select
            </button>
          </div>
        </div>
      </div>
    </Fade>
  </div>
</template>

<script>
import Collapse from '../Collapse.vue';
import Loading from '../Loading.vue';
import {Fade} from "@/components/transitions";
import {AddressForm} from '@/forms/orders';
import {DropoffForm} from "@/forms/orders";
import {favorites} from "@/api";
import {toaster} from "@/common/helpers";
import { PriceForm } from '@/forms/orders';
import {
  Input,
  SubmitButton,
  TelInput,
  SearchAddress,
} from '@/components/forms';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import MapLink from '../forms/mapLink.vue';
import api from "@/api/api";

export default {
  name: "Address",

  components: {
    Collapse,
    Loading,
    Fade,
    Input,
    SubmitButton,
    TelInput,
    SearchAddress,
    MapLink,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      default: 0,
    },

    name: {
      type: String,
      default: '',
    },

    searchCaption: {
      type: String,
      default: '',
    },

    showDetail: {
      type: Boolean,
      default: false,
    },

    autoPhoneCountry: {
      type: Boolean,
      default: true,
    },
    isDropoff: {
      type: Boolean,
      default: false,
    },
    isConfirmAdd:{
      type:Object,
      default:null,
    },
    confirmAdd: {
    type: Function,
    default: null,
  },

  cancelAdd: {
    type: Function,
    default: null,
  }
  },

  data() {
    return {
      bodyVisible: false,
      favoritesModalVisible: false,
      selectedFavorite: {},
      // googleInput:'',
      valueinput: '',
      pickups: [],
      selectedPickup: 'Select Pickup',
    };
  },

  computed: {
    ...mapGetters('auth', ['isBusinessUser']),
    ...mapState(['isLoading']),
    ...mapState(['myPriceFormNew']),
    ...mapState({
      favorites: ({favorites}) => favorites.list,
      paginateInfo: ({favorites}) => favorites.paginateInfo,
    }),
    showLoadMore() {
      return this.paginateInfo.last_page != 1 && this.paginateInfo.current_page != this.paginateInfo.last_page;
    }
  },

  setup(props) {
    let form = null;
    const formPrice = PriceForm();
    if (props.isDropoff) {
      form = DropoffForm();
    } else {
      form = AddressForm();
    }
    const {v$} = form;

    return {
      form,
      formPrice,
      v$,
    }
  },

  mounted() {
    this.form.assign(this.modelValue);
    this.bodyVisible = this.showDetail;

    if (!this.isDropoff && this.isBusinessUser) {
      this.fetchPickups();
    }
  },

  methods: {
    ...mapActions('favorites', ['addGetFavorites', 'getFavorites']),
    ...mapMutations( 'orders',['setFormPriceData']),
    async showFavoritesModal() {
      this.selectedFavorite = {};

      await this.getFavorites();
      this.favoritesModalVisible = true;

      setTimeout(() => this.bodyVisible = true, 0);
    },
    logger(data){
      console.log(data,"logger");
    },

    submit() {
    //  alert("address 365")
      // console.log(this.form.data,"--this.form.data 395")
      this.$emit('update:modelValue', this.form.data);
      this.$emit('submit', this.form.data);
      this.$emit('submitPoint', this.form.data);
      console.log("this.form.data-----", this.form.data);
      if (this.confirmAdd)
      {
        this.confirmAdd(this.index)
      } 
        
      this.bodyVisible = false;
    },

    updateLocation({lat, lng}) {
      console.log("lat, lng----", lat, lng);
      this.form.lat = lat;
      this.form.long = lng;
      console.log("form---add---", this.form);
      this.$emit('update:modelValue', this.form.data);
      this.$emit('locationChanged');
    },

    async updataLocationBlueBoard() {
      let result = await favorites.callBlueBoardApi(this.form);
      let {features} = result.data;
      //---------------
      console.log(result,"Invalid Location 396")
      if (!features) {
        toaster().error("Invalid Location !!!")
        return;
      }

      if (!features[0]?.geometry) {
        // toaster().error("Invalid Location !!!")
        return false;
      }
      //---------------
      let {x, y} = features[0].geometry;
      this.form.lat = y;
      this.form.long = x;

      this.$emit('locationChanged');
    },

    findMap() {
      this.$emit('update:modelValue', this.form.data);
      this.$emit('BlueBoardSearch')
    },

    submitFavorite() {
      this.selectedPickup='Other'
      const {
        coordinate,
        name,
        address,
      } = this.selectedFavorite;

      this.form.lat = +coordinate.split(',')[0];
      this.form.long = +coordinate.split(',')[1];
      this.form.name = name;
      this.form.address = address;
      this.formPrice['pickup_id'] = null;
        // console.log("--this.formPrice null",this.formPrice)
        this.setFormPriceData({pickup_id:null});
      this.favoritesModalVisible = false;

      this.$emit('update:modelValue', this.form.data);
      console.log(this.form.data,"this.form.data 461")
      this.$emit('updateFavorite', this.form);
    },
    getInputValue(value) {
      this.valueinput = value;
    },
    googleUrl() {
      this.$emit('GoogleUrlSearch', this.valueinput, this.index)
    },
    async changePage(page) {
      await this.addGetFavorites(page);
    },

    /**
     * Fetch Pickups
     */
    fetchPickups() {
      api().post('v1/user/getpickups').then(res => {
        this.pickups = res.data.data.pickups
        if(res.data.status==="error" && res?.data["http-code"]==200)
        {
          toaster().success(res.data.message);
        }
        // console.log(res.data.status,res?.data["http-code"],"this.pickups")
      })
    },

    /**
     * pickupUpdated Event Fire when pickup updated from select
     */
    pickupUpdated() {
      console.log(this.selectedPickup,"this.selectedPickup");
      if (!['Select Pickup', 'Other'].includes(this.selectedPickup)) {
        let pickupData = JSON.parse(JSON.stringify(this.modelValue))

        pickupData.lat = Number(this.selectedPickup.latitude);
        pickupData.long = Number(this.selectedPickup.longitude);
        pickupData.pickup_location = this.selectedPickup.location
        pickupData.pickup_id = this.selectedPickup.id
        this.formPrice['pickup_id'] = this.selectedPickup.id
// console.log(this.selectedPickup.id,585885888)
        this.$emit('update:modelValue', pickupData)
        this.$emit('locationChanged');
        this.setFormPriceData({pickup_id:this.selectedPickup.id});
      }
      else{
        // added to remove pickup_id from formPrice
        this.formPrice['pickup_id'] = null;
        // console.log("--this.formPrice null",this.formPrice)
        this.setFormPriceData({pickup_id:null});
      }
    },
    

    /**
     * Submit pickup
     */
    submitPickup() {
      let pickupData = JSON.parse(JSON.stringify(this.modelValue))
      // console.log(this.selectedPickup,"459 address");
      // updating formPrice when pickup Changes 

      console.log(this.form.data.phone,"this.form.data")
      console.log(this.form.data,"this.form.data")
      this.formPrice['pickup_id'] = this.selectedPickup.pickup_id
      this.formPrice['isBusinessUser']=this.isBusinessUser;
      this.formPrice['vehicle_id'] = 2

      pickupData.lat = Number(this.selectedPickup.latitude);
      pickupData.long = Number(this.selectedPickup.longitude);
      pickupData.pickup_location = this.selectedPickup.location
      pickupData.pickup_id = this.selectedPickup.id
      // adding description value when pick up selected 
      pickupData.description = this.form.data.description
      pickupData.phone = this.form.data.phone
      console.log("--pickupData",pickupData)
      this.$emit('update:modelValue', pickupData);
      this.$emit('submit', pickupData);
      this.$emit('submitPoint', pickupData);
      // for button enable req. order on confirm
      if (this.confirmAdd)
      {
        this.confirmAdd(this.index)
      } 
      // console.log(pickupData,"pickupData 517")
      this.bodyVisible = false;
    },
  },

  watch: {
    modelValue() {
      this.form.assign(this.modelValue, true);
    },
    showDetail() {
      this.bodyVisible = this.showDetail;
    }
  },

  unmounted() {
    this.form.reset();
  },
}
</script>
<style scoped>
.spinner-border {
  width: 25px;
  height: 25px;
  margin-bottom: 0;
}

.custom-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

.custom-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: 'Bariol', sans-serif;
}

.custom-modal > .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204A;
  font-weight: bold;
  font-size: 20px;
}

.custom-modal > .modal-header > button {
  font-weight: normal;
  border: none;
  outline: none;
  background: transparent;
  color: #00204A;
}

.custom-modal > .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #00204A;
  max-height: 320px;
  overflow-y: scroll;
}

.custom-modal > .modal-body > .load-more {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  padding: 12px 4px;
}

.custom-modal > .modal-body > .load-more > .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.custom-modal > .modal-body > .favorite-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  padding: 12px 4px;
  cursor: pointer;
}

.custom-modal > .modal-body > .favorite-item.selected {
  background: #F2DDC8;
}

.custom-modal > .modal-body > .favorite-item > img {
  width: 24px;
}

.custom-modal > .modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.custom-modal > .modal-footer > button {
  flex-grow: 1;
  background: #00B7C2;
  border-radius: 5px;
  padding: 14px 0;
  color: #fff;
  font-size: 20px;
  border: none;
  outline: none;
}
</style>
<style>
.custom-gap {
  gap: 11px;
}

.house-number label {
  font-size: 12px;
}

.input-group-text > img {
  width: 16px;
}

.selected-favorite {
  background-color: var(--color-active-bg);
}

.zone-line {
  border-radius: 5px;
}

input.blue-plate-input {
  border: 1px solid #00204A;
  border-right: none;
}

.custom-border {
  border: 1px solid #00204A;
  border-left: none;
}

.blue-plate-input > input {
  border: 1px solid #00204A;
}

.form-floating > label {
  padding: 1.2rem;
}

.btn-find {
  background-color: #00204A !important;
  border: 1px solid #00204A;
  color: #f1f1f1 !important;
  padding: 13px 0;
}

.btn-find:hover {
  border: 1px solid #00204A;
  background-color: #003d84 !important;
}

</style>

<style>
.accordion-item {
  border: 1px solid #cacaca !important;
  margin-bottom: 8px;
  border-radius: 0.375rem !important;
}

.accordion-header-blue-board {

}

.accordion-header-google {

}

.accordion-button {
  color: #00204A !important;
  background-color: inherit;
}

.accordion-button:focus {
  background-color: inherit;
}

.accordion-button::after {
  display: none;
}

.accordion-button > img {
  transition: all 200ms ease-in-out;
}

.accordion-button:not(.collapsed) > img {
  transform: rotate(-90deg);
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: inherit;
}

.google-url-input:focus {
  outline: none;
  border-color: #CACACA;
  box-shadow: none;
}

.favorite-list {
  max-height: 300px;
  overflow-y: auto;
}

.btn-primary-peyk {
  padding: 13px 63px;
  width: fit-content;
}

.btn-primary-peyk:disabled {
  background: #F2F2F2 !important;
  color: #00204A !important;
  opacity: unset !important;
  padding: 13px 63px;
  min-width: unset;
  width: fit-content;
  height: unset;
}

.accordion-button .blue-plate-img {
  margin-right: 12px;
  width: 15px;
}

.accordion-button .google-map-icon {
  margin-right: 8px;
  width: 23px;
}
</style>