<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
</style>

<template>
  <div id="peyk_bodynav">
    <div class="container-fluid">
      <PageTitle :title="'#' + filterData.id + 'Pass'" />
      <div class="row rspons-row mt-5">
        <div class="col-md-12 col-lg-6 mb-3">
          <div class="order-history-leftside">
            <div class="row align-items-center">
              <div class="col-12 justify-content-between mb-1">
                <div class="d-flex justify-content-between">
                  <p class="order-history-leftside-id-title">
                  Order #{{ filterData.id }}
                </p>
                <div class="d-flex gap-2 align-items-center">
                  <button 
                  v-if="filterData?.TookanOrderStatus !== 'Cancelled' && filterData?.TookanOrderStatus  "
                  @click="goTrackOrders(filterData.id, $event)" class="btn btn-primary btn-track-order p-2">
              Track order
            </button>
                  <!-- <button
                    v-if="filterData?.TookanOrderStatus !== 'Cancelled'"
                    class="cancel-button px-3 p-2"
                    @click="cancelOrder(filterData.id)"
                  >
                    Cancel
                  </button> -->
                  <button
                    v-if="filterData?.TookanOrderStatus !== 'Cancelled' && filterData?.TookanOrderStatus  "
                    class="cancel-button px-3 p-2"
                    @click="cancelOrder(filterData.id)"
                    :disabled="isLoading" 
                  >
                    <span v-if="!isLoading">Cancel</span>
                    <span v-else class="loader"></span>
                  </button>
                </div>
                </div>
              </div>
              <!-- <div class="col-6">
                <p class="order-history-leftside-id-title">
                  Order #{{ filterData.id }}
                </p>
              </div> -->
              <!-- <div class="col-12 col-md-6"> -->
              <div class="col-12">
                <div class="row">
                  <div class="col-6 d-table my-auto">
                    <p class="order-history-leftside-date-titlex">
                      <img
                        src="@/assets/images/icons/bold-clock.png"
                        alt=""
                        class="pe-2"
                        style="width: 25px"
                      />{{ created_at_to_local }}
                    </p>
                  </div>
                  <div class="col-6">
                    <!-- <p class="order-history-leftside-enddelivry-title text-end" :class="{'text-danger': filterData.order_status == 'cancel'}">
                      {{filterData.order_status == "cancel" ? "Cancelled" : filterData.order_status}} -->
                    <p class="order-history-leftside-enddelivry-title text-end" :class="{'text-danger': filterData.TookanOrderStatus == 'Cancelled'}">
                      {{ filterData.TookanOrderStatus}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr class="peyk-hr-style mb-3" />
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="card">
                  <div class="card-header">
                    <p class="order-history-title">
                      <span class="new-circle"></span>
                      Pickup Address
                    </p>
                  </div>
                  <ul class="list-group list-group-flush cardlist">
                    <li class="list-group-item">
                      <p class="order-history-second-title">Address</p>
                      <p class="order-history-text">
                        {{ filterData?.sender_address }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Sender name</p>
                      <p class="order-history-text">
                        {{ filterData?.sender_name }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Phone Number</p>
                      <p class="order-history-text">
                        {{ filterData?.sender_phone }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="filterData?.sender_collect">
                      <p class="order-history-second-title">Pay	</p>
                      <p class="order-history-text">
                        QAR {{ filterData?.sender_collect }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="filterData?.sender_deposit">
                      <!-- <p class="order-history-second-title">Collect	</p> -->
                      <p class="order-history-second-title">COD Amount	</p>
                      <p class="order-history-text">
                        QAR {{ filterData?.sender_deposit }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="filterData?.sender_description">
                      <p class="order-history-second-title">Description	</p>
                      <p class="order-history-text">
                        {{ filterData?.sender_description }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 mb-3" v-for="dropoff in filterData.receiver_info" :key="dropoff.address">
                <div class="card">
                  <div class="card-header">
                    <p class="order-history-title">
                      <span class="new-square"></span>
                      <span class="align-items-end d-flex justify-content-between w-100">
                       <span>
                         Drop off Address 
                       </span>
                       <span class="text-capitalize text-danger ">
                      {{ dropoff?.status }}
                       </span>
                      </span>
                    </p>
                  </div>
                  <ul class="list-group list-group-flush cardlist">
                    <li class="list-group-item">
                      <p class="order-history-second-title">Address</p>
                      <p class="order-history-text">
                        {{ dropoff.address }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Receiver name</p>
                      <p class="order-history-text">
                        {{ dropoff.name }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Phone Number</p>
                      <p class="order-history-text">
                        {{ dropoff.phone }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="dropoff?.pay">
                      <p class="order-history-second-title">Pay</p>
                      <p class="order-history-text">
                        QAR {{ dropoff.pay }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="dropoff?.collect">
                      <!-- <p class="order-history-second-title">Collect</p> -->
                      <p class="order-history-second-title">COD Amount</p>
                      <p class="order-history-text">
                        QAR {{ dropoff.collect }}
                      </p>
                    </li>
                    <li class="list-group-item" v-if="dropoff?.description">
                      <p class="order-history-second-title">Description</p>
                      <p class="order-history-text">
                        {{ dropoff.description }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3" v-if="filterData.driver_information">
                <p class="order-history-box-title mt-3 ps-2">Driver Details</p>
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-9 col-md-8">
                        <div class="row">
                          <div class="col-5 col-sm-3 col-md-4 d-flex justify-content-center align-items-center">
                            <img
                              :src="driver_avatar"
                              class="box-img-figure"
                              alt=""
                            />
                          </div>
                          <div class="col-7 col-sm-9 col-md-8 d-table my-auto">
                            <p class="order-history-box-detail-title mb-0">
                              {{ driver_name }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 col-md-4 d-table my-auto">
                        <p class="order-history-box-detail-title text-end mb-0">
                          <img
                            src="@/assets/images/icons/Icon-awesome-star.png"
                            alt=""
                          />
                          {{filterData?.driver_information?.score ? (Math.round(filterData?.driver_information?.score * 100) / 100).toFixed(1) : "0.0"}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush cardlist">
                    <li class="list-group-item">
                      <p class="order-history-second-title">Phone Number</p>
                      <p class="order-history-text">{{ filterData?.driver_information?.mobile}}</p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Return</p>
                      <p class="order-history-text">{{filterData?.return_trip ? "Yes" : "No"}}</p>
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="col-md-6 mb-3">
                <p class="order-history-box-title mt-3 ps-2">Payment Details</p>
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-start align-items-center" style="gap: 12px;">
                      <img src="@/assets/images/icons/color-wallet.png" style="width: 30px;" alt="">
                      <h3 class="payment-type">{{filterData.payment_type}}</h3>
                      <p class="order-history-box-detail-title text-end mb-0 order-price">
                        <span class="change-font">QAR</span>
                        {{filterData.order_price - filterData.discount_amount}}
                      </p>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush cardlist">
                    <li class="list-group-item">
                      <p class="order-history-second-title">Order Cost</p>
                      <p class="order-history-text">
                        QAR {{ filterData.order_price }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Promotion Amount</p>
                      <p class="order-history-text">
                        QAR {{ filterData.order_discount ?? "0" }}
                      </p>
                    </li>
                    <li class="list-group-item">
                      <p class="order-history-second-title">Promotion Code</p>
                      <p class="order-history-text">
                        {{ filterData.discount_code > 0 ? "Yes" : "No" }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 mb-3 interaction-card" v-if="filterData.order_status != 'cancel'">
                <div class="d-flex flex-column justify-content-start align-items-stretch" v-for="signature in signatures" :key="signature">
                  <p class="order-history-box-title mt-3 ps-2">Signature</p>
                  <img class="signature-img" :src="signature" alt="Invoice">
                </div>
                <div class="d-flex flex-column justify-content-start align-items-stretch">
                  <p class="order-history-box-title mt-3 ps-2">Invoice</p>
                  <button class="invoice-button" @click.prevent="downloadInvoiceBtn"><img src="@/assets/images/icons/pdf.png" alt="PDF"></button>
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3 rate-card" v-if="filterData.order_status != 'cancel'">
                <div>
                  <p class="order-history-box-title mt-3 ps-2">Rate Driver</p>
                  <div class="rate-star">
                    <template v-for="item in [1, 2, 3, 4, 5]" :key="item">
                      <img src="@/assets/images/icons/Icon-awesome-star-color.svg" alt="Star" style="width: 33px;" v-if="item <= filterData?.driver_information?.score">
                      <img src="@/assets/images/icons/Icon-awesome-star.svg" alt="Star" style="width: 33px;" v-else>
                    </template>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-6 mb-3 map-container">
          <GoogleMap
            :geolocationEnabled="true"
            :markers="markers"
            :path="path"
            :bounds="bounds"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {orders} from "@/api";
import PageTitle from '@/components/PageTitle';
import { GoogleMap } from '@/components';
import { mapState ,mapGetters} from 'vuex';
import { mapMutations } from 'vuex';
import {toaster} from "@/common/helpers";
import { peyk } from '@/configs';
export default {
  name: 'OrderDetails',

  props: ['id'],

  components: {
    GoogleMap,
    PageTitle,
  },

  data() {
    return {
      markers: [],
      path: [],
      filterData: {},
      isLoading: false,
    };
  },

  computed: {
    bounds() {
      return this.markers.map((marker) => marker.position);
    },
    created_at_to_local() {
      const created_at = new Date(this.filterData.created_at);
      const month = created_at.getMonth() + 1; // Adding 1 to get the correct month
      return `${created_at.getFullYear()}-${month}-${created_at.getDate()} ${created_at.getHours()}:${created_at.getMinutes()}:${created_at.getSeconds()}`;
    },
    signatures(){
      const signatures = [];
      if (Object.keys(this.filterData).length){
        this.filterData.receiver_info.forEach(dropoff => {
          if (dropoff.signature){
            signatures.push(dropoff.signature);
          }
        });
      }
      return signatures;
    },
    driver_name() {
      if (this.filterData.driver_information?.firstName){
        return this.filterData.driver_information?.firstName + ' ' + this.filterData.driver_information?.lastName;
      }
      return "Unassigned Driver";
    },
    driver_avatar() {
      if (this.filterData.driver_information?.avatar){
        return this.filterData.driver_information?.avatar;
      }
      return require("@/assets/images/icons/driver-avatar.png");
    },
     ...mapState(["isLoading", "cityId"]),
    ...mapState("orders", ["clientSecret", "lastOrderId", "duration"]),
    ...mapState('cards' , ['balance']),
    ...mapGetters('auth' , ['fullname' , 'mobile', 'isBusinessUser']),
    ...mapGetters('orders' , ['vehiclePrice']),
  },

  async mounted() {
    let result = await orders.getOrderDetail({id: this.id});
    this.filterData = result.data.data;
    console.log(this.filterData,"this.filterData")
    const pickupMarker = {
      position: this.getPositionString(this.filterData.sender_location),
      url: require('@/assets/images/icons/pickup.png'),
    };

    const dropoffMarkers = [];
    const dropoffPaths = [];
    this.filterData.receiver_info.forEach((item, index)=>{
      dropoffMarkers.push({
        position: {
          lat: parseFloat(item.lat),
          lng: parseFloat(item.long),
        },
        url: require(`@/assets/images/icons/dropoffs.png`),
        index: index+1,
        data: this.filterData.receiver_info[index]
      });
      dropoffPaths.push({
        lat: parseFloat(item.lat),
        lng: parseFloat(item.long),
      });
    });

    this.markers = [pickupMarker, ...dropoffMarkers];

    this.path = [
      this.getPositionString(this.filterData.sender_location),
        ...dropoffPaths,
    ];
    console.log("this.path",this.path);
  },

  methods: {
    getPositionObject(locationObject) {
      const { lat, long } = locationObject;
      return {
        lat: parseFloat(lat),
        lng: parseFloat(long),
      };
    },

    getPositionString(locationString) {
      const location = locationString.split(',');
      return {
        lat: parseFloat(location[0]),
        lng: parseFloat(location[1]),
      };
    },
    async downloadInvoiceBtn(e) {
      const buttonTag = e.composedPath().find((el)=>el.classList.contains("invoice-button"));
      const buttonContent = buttonTag.innerHTML;
      try {
        buttonTag.setAttribute('disabled',"");
        buttonTag.innerHTML = `<span style="width: 25px; height: 25px; margin: 0; padding: 0;" class="spinner-border"></span>`;
        const response = await orders.downloadInvoice(this.filterData.id);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const downloadTag = this.$refs.downloadTag;
        downloadTag.href = url;
        downloadTag.download = `Invoice Order #${this.filterData.id}`;
        downloadTag.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }finally {
        buttonTag.removeAttribute('disabled');
        buttonTag.innerHTML = buttonContent;
      }
    },
    async cancelOrder(orderID) {
      if (confirm('Are you sure you want to cancel order ?')) {
        this.isLoading = true;
        let result = await orders.orderCancel({order_id:orderID});
        if(result?.data?.status)
        {
          toaster().success(result?.data?.message);
          this.isLoading = false;
          this.removeOrder(orderID);
          this.$router.push({name: 'CurrentOrder'});

        }else{
          toaster().error(result?.data?.message);
          this.isLoading = false;
        }
        this.setLastOrderId(0);
        // window.location.reload()
      }
    },
    goTrackOrders(orderID) {
     let base64Encoded= window.btoa( orderID )
  
      const url = `${peyk.host}track-order/${base64Encoded}`; 
      window.open(url, '_blank');
    },
    ...mapMutations('orders', ['setLastOrderId' , 'removeOrder']),
  },
  
};
</script>

<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}
.map-container{
  border: 3px solid #D2D2D2;
  border-radius: 21px;
  padding: 0;
  overflow: hidden;
}
.new-circle{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #00B7C2;
}
.new-square{
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 10px;
  background: #00204A;
}
.order-history-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  color: #BCBCBC;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.order-history-box-title{
  color: #BCBCBC !important;
  font-family: 'Nunito', sans-serif;
  font-size: 18px !important;
  font-weight: normal;
}
.order-history-second-title{
  color: #BCBCBC;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.list-group-item > p.order-history-text{
  color: #00204A;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: normal;
}
p.order-history-box-detail-title{
  color: #00204A;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.payment-type{
  text-transform: capitalize;
  font-size: 18px;
  margin: 0;
}
.order-price{
  margin-left: auto;
  color: #00B7C2 !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold !important;
  font-size: 20px !important;
}
.order-price > .change-font{
  font-size: 12px !important;
}
.interaction-card{
  display: flex;
  justify-content: flex-start;
  gap: 28px;
}
.loader {
  border: 2px solid #ffffff;
  border-top: 2px solid #00b7c2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.signature-img{
  width: 100px;
  height: 80px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
}
.invoice-button{
  outline: none;
  padding: 22px 30px;
  background: #fff;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rate-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 28px;
}
.rate-star{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fbfbfb94;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:focus {
  background: rgb(88, 88, 88);
}
p.order-history-leftside-enddelivry-title::first-letter {
  text-transform: capitalize;
}
</style>
<style scoped src="@/assets/css/order-details.css"></style>
