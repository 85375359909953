import { orders } from "@/api";
import { showErrorIfExists, isSuccessful, showMessage } from "@/common/helpers";

const state = () => ({
  vehicles: [],
  getOrderResp: {},
  duration: null,
  currentOrders: [],
  paginateInfo: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0
  },
  orders: [],
  clientSecret: null,
  lastOrderId: null,
  discount_list: [],
  myPriceFormNew: {},
});

const mutations = {
  setPaginate(state, payload) {
    state.paginateInfo = payload;
  },

  setVehicles(state, payload) {
    state.vehicles = payload;
  },
  setOrderResp(state,payload){
    state.getOrderResp=payload
  },

  setDuration(state, payload) {
    state.duration = payload;
  },

  setCurrentOrders(state, payload) {
    if (payload.constructor === Array){
      state.currentOrders = payload;
    }else {
      if (payload.id){
        state.currentOrders = [payload];
      }
    }
  },

  addToCurrentOrders(state, payload) {
    state.currentOrders = [...state.currentOrders, payload];
  },

  removeOrder(state, payload) {
    state.currentOrders = [
      ...state.currentOrders.filter((order) => order.id != payload),
    ];
  },

  setOrders(state, payload) {
    state.orders = payload;
  },

  setClientSecret(state, payload) {
    state.clientSecret = payload;
  },

  setLastOrderId(state, payload) {
    state.lastOrderId = payload;
  },

  setDiscountList(state, payload){
    state.discount_list = payload;
  },
  setFormPriceData(state, payload) {
    state.myPriceFormNew = payload;
  },
};

const actions = {
  async getDiscountList({ commit }){
    const { data } = await orders.getDiscountList();
    console.log(data);
    commit("setDiscountList", data.data.discounts);
  },

  async getOrderPrice({ commit }, payload) {
    const { data } = await orders.getOrderPrice(payload);

    commit("setOrderResp",data);
    commit("setVehicles", Array.isArray(data.data.results) ? data.data.results : [data.data.results]);

    showErrorIfExists(data);
    return isSuccessful(data);
  },

  async getOrderDuration({ commit }, payload) {
    const { data } = await orders.getOrderDuration(payload);
    commit("setDuration", data.data.results);
    showErrorIfExists(data);
    return isSuccessful(data);
  },

  async getCurrentOrders({ commit }) {
    const { data } = await orders.getCurrentOrders();
    commit("setCurrentOrders", data.data);
  },

  async getOrders({ commit }, payload) {
    if (!payload){
      payload = 1;
    }
    const { data } = await orders.getOrders(payload);
    commit("setOrders", data.data.result);
    commit('setPaginate', {
      current_page: data.data.current_page,
      last_page: data.data.last_page,
      per_page: data.data.per_page,
      total: data.data.total
    });
  },

  clearVehicles({ commit }) {
    commit("setVehicles", []);
    commit("setOrderResp", {});
  },

  setLastOrderId({ commit }, payload) {
    commit("setLastOrderId", payload);
  },

  async orderSubmit({ commit }, payload) {
    let { data } = await orders.orderSubmit(payload);
    if (isSuccessful(data)) {
      showMessage(data);

      commit("addToCurrentOrders", data.data);
      commit("setLastOrderId", data.data.id);
    }
    return data;
  },
  updatemyPriceFormNew({ commit }, data) {
    commit('setFormPriceData', data);
  },
};

const getters = {
  currentOrdersCount: (state) => state.currentOrders.length || 0,
  vehiclePrice: (state) => state.vehicles[0].discount_price ?? 0,
  vehiclePriceArr: (state) => state.vehicles,
  myPriceFormData: (state) => state.myPriceFormNew,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
