<template>
  <div id="peyk_bodynav">
    <div class="container-fluid driver-searching">
      <!-- Request Order for searching drivers page  -->
      <div class="lightbox-driver-searching" v-if="showRequestBox">
        <div class="cancelOrder-btn-peyk">
          <div class="first-circle">
            <div class="second-circle">
              <div class="third-circle">
                <img src="@/assets/images/icons/pass-icon.png" style="width: 194px;" alt=""/>
              </div>
            </div>
          </div>
          <div class="cancel-button">
            <button
                type="button"
                class="btn"
                id="cancel-order"
                @click="cancelOrder"
            >
              Cancel Order
            </button>
          </div>
        </div>
      </div>
      <!-- /Request Order for searching drivers page -->
      <div class="row ps-2" style="height: 100%">
        <div class="col-12 col-md-5 col-lg-4 col-xl-3 pt-4 left-side pe-4 d-flex flex-column">
          <!-- accordion ------------------------------------------------------->
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <!-- <Address
                v-model="pickup"
                @BlueBoardSearch="selectPickupBlueBoard"
                @GoogleUrlSearch="selectPickupGoogleUrl"
                @updateFavorite="pickupFavorite"
                @submit="setState(2, 'pickup')"
                name="Sender Name"
                @locationChanged="selectPickup"
                search-caption="Search Pickup Address"
                class="mb-3"
                :show-detail="pickupDetail"
                :autoPhoneCountry="false"
            >
              <span class="me-md-2 me-lg-3 circle"></span>
              <span style="user-select: none">{{pickupMessage}}</span>
              <span class="d-none">NW10</span>
            </Address> -->


            <div v-for="(field, index) in formDropoff" :key="index">
              <div v-if="index == 0">
                <Address
                    v-model="pickup"
                    @BlueBoardSearch="selectPickupBlueBoard"
                    @GoogleUrlSearch="selectPickupGoogleUrl"
                    @updateFavorite="pickupFavorite"
                    @submit="setState(2, 'pickup')"
                    @submitPoint="getInfoTwoPoint(index)"
                    name="Sender Name"
                    @locationChanged="selectPickup"
                    search-caption="Search Pickup Address"
                    class="mb-3"
                    :show-detail="pickupDetail"
                    :autoPhoneCountry="false"
                    :index="index"
                    :isConfirmAdd="isConfirmAdd"
                    :confirmAdd="() => confirmAddHandler(index)" 
                    :cancelAdd="() => cancelAddHandler(index)" 
                >
                  <span class="me-md-2 me-lg-3 circle"></span>
                  <span style="user-select: none">{{pickupMessage}}</span>
                  <span class="d-none">NW10</span>
                </Address>
              </div>
              <div v-else>
                <Address
                  v-model="formDropoff[index]"
                  @submit="checkDropOffZones(index)"
                  @submitPoint="getInfoTwoPoint(index)"
                  @BlueBoardSearch="selectDropoffBlueBoard(index)"
                  @GoogleUrlSearch="selectDropoffGoogleUrl"
                  @updateFavorite="dropoffFavorite(index)"
                  @locationChanged="selectDropoff(index)"
                  name="Receiver Name"
                  search-caption="Search Dropoff Address"
                  class="mb-3"
                  :show-detail="dropoffDetail[index]"
                  :is-dropoff="true"
                  :index="index"
                  :cancelAdd="() => cancelAddHandler(index)" 
                  :isConfirmAdd="isConfirmAdd" 
                  :confirmAdd="() => confirmAddHandler(index)" 
                >
                    <div class="col-1" style="flex-shrink: 1;">
                      <img src="@/assets/images/icons/square.png" class="me-md-2 me-lg-3" />
                    </div>
                    <div class="col-9 ms-2" style="flex-shrink: 1;">
                      <span style="user-select: none">{{ field?.label }}</span>
                      <span class="d-none">NW10</span>
                    </div>
                    <button v-if="index != 1" :form="form" class="col-1 btnstyle-dropoff" @click.stop="removeField(index)" style="flex-shrink: 1; order: 2;">
                      <img src="@/assets/images/icons/closedrop.png" class="close-icon" alt="close">
                    </button> 
              </Address>
              <!-- <div v-if="field.price && !pickup.pickup_id" class="dropoff-price"><span> Price: QAR  -->

                <!-- code before error message show -->

                <!-- {{ field.price }}  -->
                <div v-if="vehiclePriceArr[0]?.dropPricingArr[index-1]" class="dropoff-price"><span> Price: QAR 
                {{ vehiclePriceArr[0]?.dropPricingArr[index-1]?.price }} 
                </span></div>
                
              </div>
            </div>
          </div>
          <button ref="plusbtn" @click="addField" class="btn plusbtn" v-if="showAddFieldButton"><img src="@/assets/images/icons/adddrop.png" alt="plus">Add New DropOff</button>

          <!-- <div v-if="isBusinessUser && pickup?.pickup_id && isShowVehicles" class="col-12 pt-2 pb-4">
            <div class="fs-6 pb-2">Select Vehicle</div>
            <div class="form-check py-1" v-for="vehicle in vehicles" :key="vehicle.id">
              <input class="form-check-input" type="radio" name="vehicles" :value="vehicle.id" v-model="selectedVehicle" :id="`vehicle-${vehicle.id}`" >
              <label class="form-check-label" :for="`vehicle-${vehicle.id}`">
                {{ vehicle.name }}
              </label>
            </div>
          </div> -->

          <schedule v-model="schedule" @ScheduleSelected="onScheduleSelected" />
          <Payment v-model="vehicleData" :checkPrice="showVehicle" @payment-error="(val) => paymentError = val" @paymentTypeChange="PaymentTypeChange" @changeVehicle="setStateVehicle"/>

          <!-- request order button -->
          <!-- <button :style="`background-color: ${isValidRequestBtn ? 'gray' : '#00B7C2'}`"
              v-if="showRequestButton"
              class="request-order"
              :disabled="isValidRequestBtn || isLoading"
              @click="submit"
          >
            <Loading/>
            Request Order
          </button> -->
          <button :style="`background-color: ${isValidRequestBtn || !getOrderResp?.status || Object?.values(isConfirmAdd)?.includes(false) ? 'gray' : '#00B7C2'}`"
              v-if="showRequestButton"
              class="request-order"
              :disabled="isValidRequestBtn || isLoading || Object?.values(isConfirmAdd)?.includes(false) || !getOrderResp?.status"
              @click="submit"
          >
            <Loading/>
            Request Order
          </button>
          <!-- /request order button -->
        </div>

        <div
            class="col-12 col-md-7 col-lg-8 col-xl-9 px-0"
            style="height: 100vh"
        >
          <GoogleMap
              :center="center"
              :centerMarker="centerMarker"
              :centerMarkerEnabled="centerMarkerEnabled"
              :markers="markers"
              :path="path"
              :geolocationEnabled="true"
              :bounds="bounds"
              @dropPin="handleDropPin"
              @dragMarker="handleDragMarker"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import '@/assets/css/new-order.css';
import {  provide } from 'vue';
import {reverseGeocode} from '@/services/geocoder';
import {showErrorIfExists} from '@/common/helpers';

import {GoogleMap, Loading} from '@/components';
import {Address, Payment, Schedule} from '@/components/orders';

import {Order} from '@/forms/orders';
import {mapActions, mapMutations, mapState ,mapGetters} from 'vuex';
import {loadStripe} from '@stripe/stripe-js';
import {orders , favorites} from '@/api';
import router from '@/router';
import {peyk} from '@/configs';
import { PriceForm } from '@/forms/orders';
import { DurationForm } from '@/forms/orders';
import { PickupForm } from '@/forms/orders';

import {toaster} from "@/common/helpers" ;
import axios from "axios";
import api from "@/api/api";

export default {
  name: 'NewOrder',

  components: {
    Address,
    GoogleMap,
    Loading,
    Payment,
    Schedule
  },

  data() {
    return {
      dropoffZoneError: false,
      paymentError: false,
      isShowVehicles: false,
      state: 0,
      showRequestBox: false,
      markers: [],
      path: [],
      center: null,
      order_id: null,
      orderStatusInterval: null,
      currentPrice :0,
      payment_type: null,
      isShowdropoff2: false,
      isShowdropoff3: false,
      // open/close address component
      pickupDetail: false,
      dropoff1Detail: false,
      dropoff2Detail: false,
      dropoff3Detail: false,
      // address component submit check
      pickupConfirmation: false,
      dropoff1Confirmation: false,
      dropoff2Confirmation: false,
      dropoff3Confirmation: false,
      pickupMessage: "Where is your pickup?",
      dropoff1Message: "Where is your Drop off?",
      dropoff2Message: "Where is your Drop off?",
      dropoff3Message: "Where is your Drop off?",

      fields: [{ value: '' }],
      showAddFieldButton: true,
      dropoffMessages: Array.from({ length: 11 }, () => "Where is your Drop off?"),
      dropoffDetail: Array.from({ length: 11 }, () => false),
      dropoffConfirmationProps: Array.from({ length: 11 }, () => false),
      addInitialDropOff:  {
        building_no: null,
        zone_no: null,
        street_no: null,
        type: "dropoff",
        lat: null,
        long: null,
        name: null,
        phone: null,
        postcode: null,
        address: null,
        description: null,
        label: "Where is your Drop off?",
        collect: 0,
        deposit: 0,
      },
      vehicles: [],
      selectedVehicle: null,
      isConfirmAdd: {},
      favoritesList:null,
      // selectedVehicle: 2
    };
  },

  computed: {
    ...mapState(["isLoading", "cityId"]),
    ...mapState("orders", ["clientSecret", "lastOrderId", "duration","getOrderResp"]),
    ...mapState('cards' , ['balance']),
    ...mapGetters('auth' , ['fullname' , 'mobile', 'isBusinessUser']),
    ...mapGetters('orders' , ['vehiclePrice',"vehiclePriceArr"]),

    isValidRequestBtn() {
      // button enable disable
      return !this.formDropoff?.filter(item => item.type == 'dropoff' && item?.lat && item?.long)?.length || this.paymentError || this.dropoffZoneError
    },

    bounds() {
      let tempMarkers = this.markers.filter(item => item.position?.lat != null);
      return tempMarkers.length < 2 ? [] : tempMarkers.map((marker) => marker.position);
    },

    centerMarker() {
      return "";
    },

    centerMarkerEnabled() {
      return [0, 2].includes(this.state);
    },

    showVehicle() {
      return this.state >= 4 && this.checkDropoffPickup;
    },

    showCards() {
      return this.state >= 4 && this.checkDropoffPickup;
    },

    showRequestButton() {
      return this.state >= 4 && this.payment_type != null && this.checkDropoffPickup;
    },

    // checkDropoffPickup() {
    //   const pickup = this.pickup.lat != null && this.pickup.name && this.pickup.phone;
    //   const dropoff_1 = this.dropoff_1.lat != null && this.dropoff_1.name && this.dropoff_1.phone;
    //   const dropoff_2 = this.dropoformff_2.lat != null ? this.dropoff_2.name && this.dropoff_2.phone : true;
    //   const dropoff_3 = this.dropoff_3.lat != null ? this.dropoff_3.name && this.dropoff_3.phone : true;
    //   return pickup && dropoff_1 && dropoff_2 && dropoff_3;
    // },

    checkDropoffPickup() {
      const pickup = this.pickup.lat != null && this.pickup.name && this.pickup.phone;
      let dropoffsValid = true;

      const dropoffs = this.formDropoff.filter(item => item.type === "dropoff");

      for (const dropoff of dropoffs) {
        if (dropoff.lat != null) {
          if (!dropoff.name || !dropoff.phone) {
            dropoffsValid = false;
            break;
          }
        }
      }

      return pickup && dropoffsValid;
    },

    schedule: {
      get() {
        return this.form.schedule;
      },
      set(value) {
        this.form.schedule = value
      }
    },

    pickup: {
      get() {
        return this.form.addresses[0];
      },

      set(value) {
        this.form.addresses[0] = {...value};
      },
    },

    formDropoff: {
        get() {
          return this.form.addresses;
        },
        set(value) {
          const index = value.index;
          delete value.index;

          if (value.remove) {
            this.form.addresses.splice(index, 1);
          } else {
            this.form.addresses[index] = { ...value };
          }
        },
    },

     vehicleData: {
      get() {
        return this.form.data;
      },
      set(value) {
        const { discount_code, vehicle, quantity ,discount_price} = value;
        this.form.data.vehicle_id = 2;
        this.form.data.discount_code = discount_code;
        this.form.data.vehicle = vehicle;
        this.form.data.quantity = quantity;
        this.form.data.discount_price = discount_price;
      },
    },
  },

  setup() {
    const form = Order();
    const formPrice = PriceForm();
    const durationForm = DurationForm();
    const pickAddress=PickupForm()
    const v$ = form.v$;
    provide('sharedForm', formPrice);
    return {
      formPrice,
      durationForm,
      form,
      pickAddress,
      v$,
    };
  },

  async mounted() {
    this.getBalance();
    if (this.lastOrderId) {
      this.callOrderStatus(this.lastOrderId);
    }
    //-----------------------------
    this.form.reset();
    this.clearVehicles();
    this.form.city_id = 2;
    this.form.vehicle_id = 2;
    this.form.addresses[0].name = this.fullname;
    this.form.addresses[0].phone = this.mobile;
// console.log(this.pickAddress,256)
    this.fetchFleets()
    // const { data } = await favorites.getList(1);
    //   this.favoritesList=data.data;
    //   console.log(this.favoritesList?.result,"this.favorites")
    //   this.markers = this.favoritesList?.result?.map((ele) => {
    //   const [lat, lng] = ele.coordinate.split(',').map(coord => parseFloat(coord.trim()));
        
    //     return {
    //       position: {
    //         lat: lat,
    //         lng: lng
    //       },
    //       url: require("@/assets/images/icons/marker.svg"),
    //       type:"favorite"
    //     };
    //   });
  },

  methods: {
    ...mapActions('orders', ['clearVehicles']),

    onScheduleSelected(dateTime) {
      if(dateTime == 'immediate'){
        this.form.schedule = null;
      }else{
        this.form.schedule = dateTime
      }
    },
    checkCondition() {
      // Check the condition
      if (this.isBusinessUser && this.pickup?.pickup_id && this.isShowVehicles) {
        // alert(444)
        // this.formPrice['vehicle_id'] = 2
        this.formPrice['pickup_id'] = this.pickup.pickup_id
        this.formPrice['isBusinessUser'] = this.isBusinessUser
        this.formPrice.submit()
      }
    },
    confirmAddHandler(index) {
      // this.$set(this.isConfirmAdd, index, true);
      this.isConfirmAdd={...this.isConfirmAdd,[index]:true}
    },
    cancelAddHandler(index) {
      // this.$set(this.isConfirmAdd, index, false);
      this.isConfirmAdd={...this.isConfirmAdd,[index]:false}
    },
    logger(data) {
      console.log(data,"logger data");
      // console.log(this.vehiclePriceArr[0]?.dropPricingArr,"vehiclePriceArr");
    },
    updateFormAddresses() {
      this.form.addresses = this.formDropoff.map(field => ({ ...field }));
    },
    updatePriceForm() {

      const pickupAddress = this.formDropoff?.find(address => address.type === 'pickup');
      if (pickupAddress) {
        this.formPrice.pickup.lat = pickupAddress.lat;
        this.formPrice.pickup.long = pickupAddress.long;
      }

      const dropoffAddresses = this.formDropoff?.filter(address => address.type === 'dropoff');
      dropoffAddresses.forEach((address, index) => {
        this.formPrice[`dropoff_${index + 1}`] = {
          lat: address.lat,
          long: address.long,
        };
      });

      for (let i = dropoffAddresses.length + 1; this.formPrice[`dropoff_${i}`]; i++) {
        delete this.formPrice[`dropoff_${i}`];
      }
    },
    addField() {
      if (this.formDropoff.length <= 200) {
        this.formDropoff.push({ ...this.addInitialDropOff, index: this.formDropoff.length });
        
        this.formPrice[`dropoff_${this.formDropoff.length - 1}`] = {
          lat: null,
          long: null,
        };

      } else {
        this.showAddFieldButton = false;
      }
      this.updateFormAddresses()
      this.updatePriceForm()
      if(this.formDropoff.length > 200){
        this.showAddFieldButton = false;
      }
    },
    async removeField(index) {
      delete this.isConfirmAdd[index];  // delete index of a object that enable disable submit-order button
      // console.log(this.isConfirmAdd,"new value after delete")
      // this.dropoffMessages[index] = "Where is your Drop off?";
      this.formDropoff.splice(index, 1);

      for (let i = 1; i < this.formDropoff?.length+1; i++) {
        // const newIndex = i;
        // const oldIndex = i + 1;

        // this.formDropoff[i].index = newIndex;
        // this.formPrice[`dropoff_${newIndex}`] = this.formPrice[`dropoff_${oldIndex}`];
        this.formPrice[`dropoff_${i}`] = null;
        delete this.formPrice[`dropoff_${i}`];
      }


      if (this.formDropoff.length <= 100) {
        this.showAddFieldButton = true;
      }

      // for (let i = 0; i < this. dropoffMessages?.length; i++) {
      //   if (i < this.formDropoff.length) {
      //     console.log("exit");
      //   } else {
      //     this.dropoffMessages[i] = "Where is your Drop off?";
      //   }
      // }
     
      this.updateFormAddresses()
      this.updatePriceForm()
      if(index>0){
        this.getInfoTwoPoint(index)
      }
      // this.drowLins();
      // this.drowMrkers();
      // this.assignForm();
      if (this.checkDropoffPickup){
        await this.formPrice.submit();
      }
    },
    // removeField(index) {
    //   this.formDropoff[index].remove = true;
    //   if (this.formDropoff.length <= 10) {
    //     this.showAddFieldButton = true;
    //   }
    // },
    async handleDragMarker({ data, marker, index }) {
      const { latLng } = data;
      const markerCustomData = marker.customData;
      if (markerCustomData === 'pickup') {
        this.pickup.lat = latLng.lat();
        this.pickup.long = latLng.lng();
        this.pickup = await this.reverseGeocode(this.pickup, 0);
        this.formDropoff[index] = await this.reverseGeocode(this.pickup, 0);
        this.center = this.markers.length > 1 ?  this.drowLins() : this.getPosition(this.pickup);
        this.getInfoTwoPoint(1)
      } else if (markerCustomData == 'dropoff') {
        this.formDropoff[index].lat = latLng.lat();
        this.formDropoff[index].long = latLng.lng();
        this.formDropoff[index] = await this.reverseGeocode(this.formDropoff[index], index);

        this.formPrice[`dropoff_${index}`].lat = this.formDropoff[index].lat;
        this.formPrice[`dropoff_${index}`].long = this.formDropoff[index].long;

        if (this.markers.length > 1) {
          this.drowLins();
        } else {
          for (let i = 0; i < this.formDropoff.length; i++) {
            this.center = this.getPosition(this.formDropoff[i]);
          }
        }

        this.getInfoTwoPoint(index)
        if(index>=1 && !!this.formDropoff[index+1]){
          this.getInfoTwoPoint(index+1)
        }
      }
      this.checkDropOffZones(index);

      this.setState(2);
    },
    dropoffConfirmation(index) {
      if (index === 0) {
        return true;
      }

      for (let i = 0; i < index; i++) {
        if (!this.formDropoff[i].address) {
          return false;
        }
      }

      return true;
    },
    async handleDropPin(data) {
      const { latLng } = data;

      if (!this.pickup.address){
        this.pickup.lat = latLng.lat();
        this.pickup.long = latLng.lng();
        this.pickup = await this.reverseGeocode(this.pickup, 0); // return { address , street_name ,postcode }
        //-----------------------------------
        this.drowMrkers();

        if (this.markers.length > 1) {
          this.drowLins();
        }else {
          this.center = this.getPosition(this.pickup);
        }
        // open/close address components
        this.dropoffDetail = Array.from({ length: 100 }, () => false);
        this.pickupDetail = true;
      }
      
      for (let i = 0; i < this.formDropoff.length; i++) {
        let dropoff = this.formDropoff[i];
        
        if (!dropoff.address && this.pickupConfirmation && this.dropoffConfirmation(i)) {
          dropoff.lat = latLng.lat();
          dropoff.long = latLng.lng();
          dropoff = await this.reverseGeocode(dropoff);
          //-----------------------------------
          this.drowMrkers();

          if (this.markers.length > 1) {
            this.drowLins();
          } else {
            this.center = this.getPosition(dropoff);
          }
          
          // open/close address components
          this.pickupDetail = false;
          this.dropoffDetail[i] = true;

          break;
        }
      }
    },

    emptyVule:function (componentId){
      let cmp = document.getElementById(componentId);

      cmp.getElementsByClassName('google-url-input')[0].value='';
      cmp.getElementsByClassName('pac-target-input')[0].value='';
      cmp.getElementsByClassName('vti__input')[0].value='';

      let inputs = cmp.getElementsByClassName('peyk-input');
      inputs.forEach(input => {
        input.value='';
      });
    },
    async dropoff2(){
      this.isShowdropoff2=false;
      this.$refs.dropoff2.style.display='none';
      this.$refs.plusbtn.style.display='block';
      this.dropoff_2.lat = null;
      this.dropoff_2.long = null;
      this.emptyVule('dropoff2_');
      this.dropoff2Message = "Where is your Drop off?";

      this.drowLins();
      this.drowMrkers();
      this.assignForm();
      if (this.checkDropoffPickup){
        await this.formPrice.submit();
      }

    },
     assignPoint(source, target) {
      source.lat = target.lat;
      source.long = target.long;
    },

    // assignForm() {
    //   this.assignPoint(this.formPrice.pickup, this.pickup)
    //   this.assignPoint(this.formPrice.dropoff_1, this.dropoff_1)
    //   this.assignPoint(this.formPrice.dropoff_2, this.dropoff_2)
    //   this.assignPoint(this.formPrice.dropoff_3, this.dropoff_3)
    // },

    assignForm() {
      this.assignPoint(this.formPrice.pickup, this.pickup);
      for (let i = 0; i < this.formDropoff.length; i++) {
        this.assignPoint(this.formPrice[`dropoff_${i + 1}`], this.formDropoff[i]);
      }
    },

    ...mapMutations(['setIsLoading']),
    ...mapMutations('orders', ['setLastOrderId' , 'removeOrder']),
    ...mapActions('cards' , ['getBalance']),

    setStateVehicle(data){

      this.currentPrice = data.discount_price;
      const previousState = Math.max(this.state, 5);
      this.state = this.state != 5 ? 5 : 5 - 1;
      if (previousState > this.state) {
        setTimeout(() => this.state = previousState, 0);
      }

    },
    async getInfoTwoPoint(index){
      
      if(index == this.form.addresses?.length){
        index = index -1
      }
      if (index > 0) {
        const pointOneIndex = index - 1;
        const pointTwoIndex = index;
      
        if (
          this.form.addresses[pointOneIndex].lat &&
          this.form.addresses[pointOneIndex].long &&
          this.form.addresses[pointTwoIndex].lat &&
          this.form.addresses[pointTwoIndex].long
        ) {
          this.durationForm.pointOne = {
            lat: this.form.addresses[pointOneIndex].lat,
            long: this.form.addresses[pointOneIndex].long,
          };
      
          this.durationForm.pointTwo = {
            lat: this.form.addresses[pointTwoIndex].lat,
            long: this.form.addresses[pointTwoIndex].long,
          };
      
          await this.durationForm.submit();

          const dropoffCount = this.form.addresses.filter(item => item.type === 'dropoff' && !!item.lat && !!item.long).length;
          const adjustment = 100 / dropoffCount;
          
          this.form.addresses[pointTwoIndex].tempPrice = this.duration?.price; // Store original price in tempPrice
          this.form.addresses[pointTwoIndex].price = this.duration.fix_price !=null ? this.duration.price : (this.duration?.price || 0) + adjustment;
          this.form.addresses[pointTwoIndex].distance = this.duration?.distance;
          this.form.addresses[pointTwoIndex].duration = this.duration?.duration;

          for (let i = 0; i < this.form.addresses?.length; i++) {
            if (this.form.addresses[i].type === 'dropoff') {
                this.form.addresses[i].price = this.duration.fix_price !=null ? this.duration.price.toFixed(2) : (((this.form.addresses[i].tempPrice )|| 0) + adjustment).toFixed(2);
            }
          }

        }
        this.drowMrkers();
        this.drowLins()
      }

    },
    setState(state, type, dropoffIndex) {
      // check if pickup and drop off is set
      // console.log(this.state,"this.state")
      if (this.state < 4) {
        if (this.checkDropoffPickup) {
          this.state = 4;
        } else {
          this.state = 2;
        }
        if(this.pickup?.pickup_id)
        {
          this.checkDropOffZones(0);  //checkDropOffZones check pick up id available or not.
        }
      } else {
        const previousState = Math.max(this.state, state);
        this.state = this.state != state ? state : state - 1;
        if (previousState > this.state) {
          setTimeout(() => this.state = previousState, 0);
        }
      }
      
      if (this.state == 4) {
        this.form.vehicle_id = 2;
      }
      
      if (type === 'pickup') {
        delete this.formPrice['isBusinessUser']

        this.pickupConfirmation = true;
        this.pickupMessage = this.form.addresses[0]?.pickup_location ?? this.form.addresses[0].address;
      } else if (type == 'dropoff') {
        this.dropoffConfirmationProps[dropoffIndex] = true;
        this.formDropoff[dropoffIndex].label = this.form.addresses[dropoffIndex].address;
        // this.dropoffMessages[dropoffIndex] = this.form.addresses[dropoffIndex].address;
      }
    },

    async orderStatus(order_id) {
      try {
        let result = await orders.orderStatus({order_id});

        if (result?.data?.data?.order_status == 'expired' ||  result?.data?.data?.order_status == 'cancel' ) {
          clearInterval(this.orderStatusInterval); // clear call request in the all project
          this.setLastOrderId(0);
          this.showRequestBox = false;
          window.location.reload()
        }

        if (result?.data.data.step > 0) {
          clearInterval(this.orderStatusInterval); // clear call request in the all project
          this.setLastOrderId(0);
          this.showRequestBox = false;
          this.form.reset();
          router.push({
            name: 'OrderTracking',
            params: {id: order_id},
          });

        }

      }catch (err){
        return;
      }
    },

    callOrderStatus(order_id, schedule = null) {
      this.order_id = order_id;
      if(schedule == null){
        this.showRequestBox = true;
        setTimeout(()=>{
          this.showRequestBox = false;
          toaster().success("Pass has accepted your order. We will be assigning our driver right away",{duration: 3000})
          setTimeout(()=>window.location.reload(),3000)
        },5000)
        // this.orderStatusInterval = setInterval(() => this.orderStatus(order_id), 6000);
      }else{
        this.showRequestBox = false;
        setTimeout(() => window.location.reload(), 6000);
      }
    },

    async cancelOrder() {
      let order_id = this.order_id;
      if (confirm('Are you sure you want to cancel order ?')) {
        await orders.orderCancel({order_id});
        this.removeOrder(order_id);
        clearInterval(this.orderStatusInterval); // clear call request in the all project
        this.setLastOrderId(0);
        this.showRequestBox = false;
        window.location.reload()
      }
    },

    getPosition(location) {
      const {lat, long} = location;
      return {lat, lng: long};
    },

    
    async getLocalAddress(location) {
      try {
        const res = await axios.get(`https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${location.long},${location.lat}`);
        if (res){
          return res.data.address;
        }
      }catch (err) {
        return {};
      }
    },

    async reverseGeocode(location, index) {
      const {lat, long} = location;
      const localAddr = await this.getLocalAddress(location);

      const {address, street, street_number, postal_code} =
          await reverseGeocode(lat, long);

      location.address = localAddr.Match_addr || address;
      location.street_name = street || street_number;
      location.postcode = postal_code;

      return {...location, index};
    },
    drowLins:function (){
      let dataPass= [];
      dataPass.push(this.getPosition(this.pickup))

      for (let i = 1; i < this.formDropoff.length; i++) {
          dataPass.push(this.getPosition(this.formDropoff[i]))
      }

      this.path = dataPass.filter(item => item.lat != null);
    },
    drowMrkers:function(){
      let dataMarker =  []
      this.formDropoff?.map((i,index)=>{
        if(index==0){
          dataMarker.push({
            type: 'pickup',
            position: this.getPosition(this.pickup),
            url: require("@/assets/images/icons/pickup.png"),
            index: 0,
            data: this.pickup
          })
        }else{
          dataMarker.push({
            type: `dropoff`,
            position: this.getPosition(this.formDropoff[index]),
            url: require(`@/assets/images/icons/dropoff-1.png`),
            index: index,
            data : this.formDropoff[index]
          })
        }

      })
      // old code start before showing favorite places
      
      this.markers = dataMarker.filter(item => item.position?.lat != null)
      
      // old code end before showing favorite places
      // new code start showing favorite places
        // Add favoritesList markers
  // let favoriteMarkers = this.favoritesList?.result?.map((ele) => {
  //   const [lat, lng] = ele.coordinate.split(',').map(coord => parseFloat(coord.trim()));

  //   return {
  //     position: {
  //       lat: lat,
  //       lng: lng
  //     },
  //     url: require("@/assets/images/icons/marker.svg"),
  //     type:"favorite"
  //   };
  // }) || []; // Handle null/undefined favoritesList

  // // Combine both dataMarker and favoriteMarkers arrays
  // this.markers = [...dataMarker, ...favoriteMarkers].filter(item => item.position?.lat != null);
  // // new code End here showing favorite places
    },
    async selectPickup() {
      this.pickup = await this.reverseGeocode(this.pickup, 0); // return { address , street_name ,postcode }
      //-----------------------------------
      this.drowMrkers();

      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.pickup);
      }
    },

    async selectPickupBlueBoard(){

      let result = await favorites.callBlueBoardApi(this.pickup);
      let {features} = result.data;
      console.log(result,"Invalid Location 901")
      if(!features){
        return;
      }

      if (!features[0]?.geometry){
        // toaster().error("Invalid Location !!!")
        return false;
      }

      const { x,y } = features[0].geometry;
      this.pickup = { ...this.pickup,    lat: y  ,long :x};
      result =  await this.reverseGeocode({ lat : y , long:x}, 0);
      this.pickup = {...this.pickup , ...result};
       this.drowMrkers();


      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.pickup);
      }

    },

    // async selectPickupBlueBoard() {
    //   try {
    //     let response = await favorites.callBlueBoardApi(this.pickup);

    //     if (!response || !response.data || !response.data.coordinates || response.data.coordinates.length !== 2) {
    //       toaster().error("Invalid Location !!!");
    //       return false;
    //     }

    //     const [x, y] = response.data.coordinates;
    //     this.pickup = { ...this.pickup, lat: y, long: x };

    //     const result = await this.reverseGeocode({ lat: y, long: x }, 0);
    //     this.pickup = { ...this.pickup, ...result };

    //     this.drowMrkers();

    //     if (this.markers.length > 1) {
    //       this.drowLins();
    //     } else {
    //       this.center = this.getPosition(this.pickup);
    //     }
    //   } catch (error) {
    //     console.error("Error in selectPickupBlueBoard:", error);
    //   }
    // },


    async selectPickupGoogleUrl(link){
      let point = this.getLocationFromGoogleMapLink(link);

      this.pickup = { ...this.pickup,    lat:parseFloat(point[0])  ,long :parseFloat(point[1])};
       this.drowMrkers();


      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.pickup);
      }
        this.pickup = await this.reverseGeocode(this.pickup, 0);
    },

    pickupFavorite(){

       this.drowMrkers();

      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.pickup);
      }
    },

    async selectDropoff(index) {
      this.formDropoff = await this.reverseGeocode(this.formDropoff[index], index)
      
      this.formPrice[`dropoff_${index}`].lat = this.formDropoff[index].lat;
      this.formPrice[`dropoff_${index}`].long = this.formDropoff[index].long;
      
      // this.dropoff[index] = await this.reverseGeocode(this.dropoff[index]);
      this.drowMrkers();
      if (this.markers.length > 1) {
        this.drowLins();
      } else {
        // this.center = this.getPosition(this.dropoff_1);
        // this.center = this.getPosition(this.dropoff_2);
        // this.center = this.getPosition(this.dropoff_3);
        
        this.center = this.getPosition(this.formDropoff[index]);
      }
      this.updatePriceForm()
    },

    async selectDropoffBlueBoard(index){
      let result = await favorites.callBlueBoardApi(this.formDropoff[index]);
      let {features} = result.data;
      console.log(result,"Invalid Location 1003")
      if(!features){ return; }
      if (!features[0]?.geometry){
        // toaster().error("Invalid Location !!!")
        return false;
      }

      const { x,y } = features[0].geometry;
      this.formDropoff[index] = { ...this.formDropoff[index], lat: y, long: x };

      result = await this.reverseGeocode({lat: y , long:x});
      this.formDropoff[index] = { ...this.formDropoff[index], ...result };

      this.formPrice[`dropoff_${index}`].lat = this.formDropoff[index].lat;
      this.formPrice[`dropoff_${index}`].long = this.formDropoff[index].long;
      
      this.drowMrkers();

      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.formDropoff[index]);
      }
    },

    // async selectDropoffBlueBoard(index) {
    //   try {
    //     let response = await favorites.callBlueBoardApi(this.formDropoff[index]);

    //     if (!response || !response.data || !response.data.coordinates || response.data.coordinates.length !== 2) {
    //       toaster().error("Invalid Location !!!");
    //       return false;
    //     }

    //     const [x, y] = response.data.coordinates;
    //     this.formDropoff[index] = { ...this.formDropoff[index], lat: y, long: x };

    //     const result = await this.reverseGeocode({ lat: y, long: x });
    //     this.formDropoff[index] = { ...this.formDropoff[index], ...result };

    //     this.formPrice[`dropoff_${index}`].lat = this.formDropoff[index].lat;
    //     this.formPrice[`dropoff_${index}`].long = this.formDropoff[index].long;

    //     this.drowMrkers();

    //     if (this.markers.length > 1) {
    //       this.drowLins();
    //     } else {
    //       this.center = this.getPosition(this.formDropoff[index]);
    //     }
    //   } catch (error) {
    //     console.error("Error in selectDropoffBlueBoard:", error);
    //   }
    // },

    async selectDropoffGoogleUrl(link, index) {
      let point = this.getLocationFromGoogleMapLink(link);

      this.formDropoff[index] = { ...this.formDropoff[index], lat:parseFloat(point[0]) ,long :parseFloat(point[1])};

      this.drowMrkers();

      if (this.markers.length > 1) {
        this.drowLins();
      }else{
        this.center = this.getPosition(this.formDropoff[index]);
      }
      this.formDropoff[index] = await this.reverseGeocode(this.formDropoff[index]);
      this.formPrice[`dropoff_${index}`].lat = this.formDropoff[index].lat;
      this.formPrice[`dropoff_${index}`].long = this.formDropoff[index].long;
    },
  
    // getLocationFromGoogleMapLink(link) {
    //   // find lat lng from google maps link with regex
    //   const regex = new RegExp('@(.*),(.*),');
    //   const latLng = link.match(regex);
    //   if (latLng.length === 3) {
    //     return [latLng[1], latLng[2]];
    //   }
    //   return undefined;
    // },


    // async getLocationFromGoogleMapLink(link) {
    //   try {
    //     const url = new URL(link);
    //     const pathSegments = url.pathname.split('/');
    //     const placeId = pathSegments[pathSegments.length - 1];
    //     console.log("placeId",placeId);

    //     const apiKey = 'AIzaSyD6AvF3mHrX2jXUKQR098ucar-ly07PkeU'; 
    //     const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`;

    //     const response = await fetch(geocodingUrl);
    //     console.log("response====",response);
    //     const data = await response.json();
    //     console.log("data===",data);

    //     if (data.status === 'OK' && data.results.length > 0) {
    //       const location = data.results[0].geometry.location;
    //       return [location.lat, location.lng];
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }

    //   return undefined;
    // },
    

    // getLocationFromGoogleMapLink(link) {
    //   const regex1 = /@(-?\d+\.\d+),(-?\d+\.\d+)/; // https://www.google.com/maps/ format
    //   const regex2 = /\/place\/.*\/@(-?\d+\.\d+),(-?\d+\.\d+)/; // https://maps.app.goo.gl/ format

    //   const latLng1 = link.match(regex1);
    //   const latLng2 = link.match(regex2);

    //   if (latLng1 && latLng1.length === 3) {
    //     return [latLng1[1], latLng1[2]];
    //   } else if (latLng2 && latLng2.length === 3) {
    //     return [latLng2[1], latLng2[2]];
    //   }
    //   return undefined;
    // },

    getLocationFromGoogleMapLink(link) {
        if((link.indexOf("https://www.google.com/maps/") >= 0) &&
            (link.indexOf("!8m2!3d") >= 0)) {

            link = link.split("!8m2!3d")[1];
            link = link.replace("?hl=en", "");
            link = link.split("!4d");

            if(link.length > 1) {
                return link;
            }
        } else if(link.indexOf("!3m") >= 0){
          link = link.split("!3m")[1];
            link = link.replace("?hl=en", "");
            link = link.split("!4d");

            if(link.length > 1) {
                return link;
            }
        }
        
        if((link.indexOf("https://www.google.com/maps/") >= 0) &&
            (link.indexOf("@") >= 0)) {

            link = link.split("@")[1];
            link = link.split(",");

            if(link.length > 1) {
                return [link[0], link[1]];
            }
        }


        return undefined;
    },

    dropoffFavorite(index){
      this.drowMrkers();

      if (this.markers.length > 1) {
        this.drowLins();
      }else {
        this.center = this.getPosition(this.formDropoff[index]);
      }
    },

    changeCard({card}) {
      this.state = 6;
      this.form.card_id = card.id;
    },

    PaymentTypeChange(paymentType){
      this.state = 6;
      this.form.payment_type = paymentType;
      this.payment_type = paymentType;
    },

    async submit() {
      this.setIsLoading(true);
      const pickupAddress = this.formDropoff?.find(address => address.type === 'pickup');
      if (pickupAddress) {
        // console.log(pickupAddress,456)
        if(pickupAddress?.pickup_id){
        this.form.pickup_id=pickupAddress.pickup_id
        }else{
          this.form.pickup_id=null
          this.form.vehicle_id=null  // when other selected then vehicle_id will be null.
        }
      }
      if (!this.payment_type ){
        this.setIsLoading(false);
        toaster().info('Please select payment type');
        return false;
      }


      if (this.payment_type == 'wallet' && this.balance < this.vehiclePrice){
        this.setIsLoading(false);
        toaster().info('You do not have enough credit in your Pass wallet');
        return false;
      }

      // this.form.schedule = this.schedule
      console.log("form--submit---",this.schedule, this.form);
      try {
        const result = await this.form.submit();
        if (result.status == 'success'){
          this.callOrderStatus(result.data.id,result.data.schedule);
        }
      }catch (err) {
        console.log(err);
        toaster().info("Something went wrong, Please try again");
      }finally {
        this.setIsLoading(false);
      }
    },

    async getStripeResult() {
      this.setIsLoading(true);

      try {
        this.stripe = await loadStripe(peyk.stripe_key); // todo: load key from settings

        const result = await this.stripe.handleCardAction(this.clientSecret);

        if (result.data) {
          showErrorIfExists(result.data);
          this.setIsLoading(false);
          return;
        }

        this.form.payment_intent_id = result.paymentIntent.id;  //this id add form
        await this.submit();
      } catch (error) {
        return this.setIsLoading(false);
      }
    },

    /**
     * Fetch fleets
     * @returns {Promise<void>}
     */
    async fetchFleets() {
      await api().post('v1/user/getFleets').then(res => {
        this.vehicles = res.data.data.vehicle
      })
    },

    async checkDropOffZones(index) {
    // alert('1257')
    console.log(index,1276)
    console.log(this.formDropoff[index].label,1276)
    // new code 09-10-24 start
    if(index)
    {
      this.formDropoff[index].label = this.form.addresses[index].address;
    }
    // new code 09-10-24 end
    // old code 09-10-24 start
    // this.formDropoff[index].label = this.form.addresses[index].address;
    // old code 09-10-24 end

      if (this.isBusinessUser && this.pickup?.pickup_id) {
        let obj = {
          pickup_id: this.pickup.pickup_id,
          coordinates: [],
        }

        let dropNo = 0;
        console.log(this.formDropoff,"this.formDropoff")
        this.formDropoff.forEach(item => {
          if (item.type == 'dropoff') {
            dropNo++;
            if(item.lat && item.long )
            {
               obj.coordinates.push({
              drop: dropNo,
              lat: item.lat,
              long: item.long,
            });
            }
            // if lat long is null then don't add them in payload
            // 09-10-24 old code
            // obj.coordinates.push({
            //   drop: dropNo,
            //   lat: item.lat,
            //   long: item.long,
            // });
          }
        })
        // console.log(obj.coordinates.length,"obj 125")
        if(!obj.coordinates.length)
        {
          return
        }
        obj.coordinates = JSON.stringify(obj.coordinates)

        await api().post('v1/user/getPickupsZones', obj).then(res => {
          const zones = res.data.data.zones;
          let wrongZones = zones.filter(zone => !zone.status);

          if (!wrongZones?.length) {
            this.dropoffZoneError = false
            this.isShowVehicles = true
          } else {
            zones.forEach(zone => {
              if (!zone.status) {
                this.dropoffZoneError = true
                this.isShowVehicles = false
                toaster().error(`Delivery not possible for Dropoff ${zone.drop}.`);
              }
            })
          }
        })
        if (this.selectedVehicle) {
          this.formPrice.submit()
        }
      } else {
        delete this.formPrice['isBusinessUser']
        this.setState(4, `dropoff`, index)
      }
      this.updatePriceForm();
      this.checkCondition();
    }
  },

  watch: {
    selectedVehicle() {
      if (this.selectedVehicle) {
        this.formPrice['vehicle_id'] = this.selectedVehicle
        this.formPrice['pickup_id'] = this.pickup.pickup_id
        this.formPrice['isBusinessUser'] = true
        this.formPrice.submit()
      }
    },
    isShowVehicles() {
      // alert('1319')
      // this.checkCondition();
    },
    //  vehicles(val){
    //   if (Object.keys(val).length){
    //     console.log("this.vehicles",this.vehicles)
    //   }
    // }
    isConfirmAdd(newValue) {
      // Emit the change to the parent
      // this.handleChange(newValue);
      console.log(newValue,"new value isconfirmed")
    },
  },

  unmounted() {
    this.form.reset();
    clearInterval(this.orderStatusInterval);
  },
};
</script>


<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.map-footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: inherit;
  margin-left: -10px;
  padding-left: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b6b6b6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #656565;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:focus {
  background: rgb(88, 88, 88);
}
.plusbtn ,.plusbtn:hover {
  color: #15609A;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.plusbtn img {
  width: 22px;
  margin-right: 16px;
}
.accordion-button::after{
  display: none !important;
}
.btnstyle-dropoff,.btnstyle-dropoff:focus{
  border: none;
  background: transparent;
  outline: none;
}
.display-dropoff{
  display: none;
}
.circle{
  background: rgba(0,183,194,0.89);
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.btnstyle-dropoff > .close-icon{
  width: 30px;
}
.dropoff-price {
    text-align: end;
    margin-top: -3px;
    margin-bottom: 5px;
}
.dropoff-price span {
    text-align: right;
    border: 1px solid #ebe9e9 !important;
    width: fit-content;
    padding: 3px;
    color: #00B7C2;
    right: 0;
}
</style>
