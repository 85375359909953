<template>
  <div id="peyk_bodynav">
    <div class="container-fluid">
      <div class="col-12 mt-5 px-4">
        <nav>
          <div class="nav nav-tabs nav-tabs-peyk-line" id="nav-tab" role="tablist">
            <button ref="current_order_tab" class="nav-link nav-link-peyk-line active" id="nav-home-tab" data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="true">Current orders
            </button>
            <button ref="past_order_tab" class="nav-link nav-link-peyk-line" id="nav-profile-tab" data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Past orders
            </button>
            <div class="order-title me-3 mb-3">
             
              <router-link to="/new-order">
                <a class="add-new-order mb-0">Add New Order</a>
              </router-link>
              <button class="add-new-order-button">
                <img src="@/assets/images/icons/plus-small.png" class="add"/>
              </button>
            </div>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <!-- tab1------------------------------------------------------------------------------------------ -->
          <div class="tab-pane fade show active pb-5" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="row ">
                <!-- Add New Order in card format old start from here -->

              <!-- <div class="col-md-6 col-lg-4 mt-4">
                <div class="card box mb-3">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="w-100">
                      <div class="d-table mx-auto order-package-image">
                        <img src="@/assets/images/icons/package.svg" alt="" style="width:100px" class="d-table mx-auto">
                      </div>
                      <p class="card-text order-title-addorder text-center mt-3">Add a new Order</p>
                      <p class="card-text order-text-addorder text-center mb-5 pb-3">Send anything to anywhere in minutes</p>

                      <router-link to="/new-order">
                        <button class="d-table mx-auto mt-5 add-new-order-button">
                          <img src="@/assets/images/icons/new-order.svg" alt="" style="width:64px">
                        </button>
                      </router-link>

                    </div>
                  </div>
                </div>
              </div> -->

              <!-- Add New Order in card format old start from here -->

                <!-- current order in card format old start from here -->

                <!-- <CurrentOrders v-for="(current,index) in currentOrders" :key="index" :value="current"/> -->

                <!-- current order in card format old end here -->
               <!-- current order in list format -->
               <div class="col-md-12 mt-4">
                  <table class="table table-bordered">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Price (QAR)</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Return</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">schedule</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(order) in currentOrders" :key="order.id">
                        <td>#{{ order.id }} Pass</td>
                        <td>QAR{{ order.user_price }}</td>
                        <td>{{ order.payment_type }}</td>
                        <td>{{ order.created_at }}</td>
                        <td>{{ order.return_trip ? "Yes" : "No" }}</td>
                        <td>{{ order.OrderStatus }}</td>
                        <td> {{ order?.schedule ? order?.schedule : 'immediate' }}</td>
                        <td class='d-flex gap-2 align-items-center flex-wrap'>
                          <button class="btn btn-primary btn-track-order" @click="goOrderDetail(order.id)">Order Details</button>
                          <button class="btn btn-danger"  v-if="order.OrderStatus !== 'Cancelled'" @click="cancelOrder(order.id)">Cancel</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div class="tab-pane fade pb-5" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="row">

                <!-- Past order in card format old start from here -->

                <!-- <PastOrders v-for="(currentOrder, index) in orders" :key="index" :value="currentOrder"/> -->

                <!-- Past order in card format old End here -->

                <div class="col-md-12 mt-4">
                  <table class="table table-bordered">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Price (QAR)</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Date</th>
                        <!-- <th scope="col">Pickup Address</th>
                        <th scope="col">Dropoff Address</th> -->
                        <th scope="col">Return</th>
                        <th scope="col">Order Status</th>
                        <!-- <th scope="col">Note for Driver</th> -->
                        <th scope="col">Schedule</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(currentOrder) in orders" :key="currentOrder.id">
                        <td>#{{ currentOrder.id }} Pass</td>
                        <td>QAR{{ currentOrder.user_price }}</td>
                        <td>{{ currentOrder.payment_type }}</td>
                        <td>{{ currentOrder.created_at }}</td>
                        <!-- <td>{{ currentOrder.sender_address }}</td>
                        <td v-for="dropoff in currentOrder.receiver_info" :key="dropoff.address">{{ dropoff.address }}</td> -->
                        <td>{{ currentOrder.return_trip ? "Yes" : "No" }}</td>
                        <td>{{ currentOrder.OrderStatus }}</td>
                        <!-- <td>{{ currentOrder.receiver_info[0].description }}</td> -->
                        <td>{{ currentOrder.schedule ? currentOrder.schedule : 'immediate' }}</td>
                        <td class='d-flex gap-2 align-items-center flex-wrap'>
                          <button class="btn btn-primary btn-track-order " @click="goOrderDetail(currentOrder.id)">Order Details</button>
                          <button v-if="currentOrder.OrderStatus.toLowerCase() !== 'cancelled'" @click="goTrackOrders(currentOrder.id)" class="btn btn-info text-white">Track Order</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              <nav class="col-12 mt-4" aria-label="Page navigation" v-if="paginateInfo.last_page !== 1">
                <ul class="pagination justify-content-center align-items-center">
                  <li class="page-item" :class="{'disabled': paginateInfo.current_page === 1}"><button class="page-link" @click="changePage(paginateInfo.current_page - 1, $event)" :disabled="paginateInfo.current_page === 1">Previous</button></li>
                  <template  v-for="page in pages" :key="page">
                    <li class="page-item disabled" v-if="page === '...'"><button class="page-link">{{page}}</button></li>
                    <li class="page-item" :class="{'active': paginateInfo.current_page === page + 1}" v-else><button class="page-link" @click="changePage(page + 1, $event)">{{page + 1}}</button></li>
                  </template>
                  <li class="page-item" :class="{'disabled': paginateInfo.current_page === paginateInfo.last_page}"><button class="page-link" @click="changePage(paginateInfo.current_page + 1, $event)" :disabled="paginateInfo.current_page === paginateInfo.last_page">Next</button></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import CurrentOrders from "@/components/CurrentOrders.vue";
// import PastOrders from "@/components/PastOrders.vue"
import '@/assets/css/order.css'
import {mapActions, mapState} from "vuex";
import { orders } from '@/api';
import { peyk } from '@/configs';
import { mapMutations } from 'vuex';
import {toaster} from "@/common/helpers";
export default {
  name: "CurrentOrder",
  components: {
    // CurrentOrders,
    // PastOrders
  },
  data() {
    return {
      fromDetail: false
    }
  },
  computed: {
    ...mapState('orders', ['currentOrders', 'orders', 'paginateInfo']),
    ...mapState('auth', ['user']),
    pages(){
      let last_page = this.paginateInfo.last_page;
      let current_page = this.paginateInfo.current_page;
      if (last_page > 10){
        // handle paginate if pages count is over 10
        let pages = [];
        switch (current_page){
          case 1:
          case 2:
          case last_page - 1:
          case last_page:
            pages = [0, 1, 2, "...", last_page - 3, last_page - 2, last_page - 1];
            break;
          case 3:
            pages = [0, 1, 2, 3, "...", last_page - 3, last_page - 2, last_page - 1];
            break;
          case last_page - 2:
            pages = [0, 1, 2, "...", last_page - 4, last_page - 3, last_page - 2, last_page - 1];
            break;
          default:
            if ([1, 2, 3].includes(current_page - 2)){
              pages = [...new Set([0, 1, 2, current_page - 2,current_page - 1, current_page, "...", last_page - 3, last_page - 2, last_page - 1])];
            }else if([last_page - 2, last_page - 1, last_page].includes(current_page + 2)){
              pages = [...new Set([0, 1, 2, "...", current_page - 2, current_page - 1, current_page, last_page - 3, last_page - 2, last_page - 1])];
            }else{
              pages = [0, 1, 2, "...", current_page - 2, current_page - 1, current_page, "...", last_page - 3, last_page - 2, last_page - 1];
            }
            break;
        }
        return pages;
      }else{
        return [...Array(last_page).keys()];
      }
    }
  },

  async mounted() {
    if (!this.fromDetail){
      this.$refs.past_order_tab.classList.remove('active');
      this.$refs.current_order_tab.classList.add('active');
    }
    await this.getCurrentOrders();
    await this.getOrders();
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      if (from.name === "OrderDetails"){
        vm.fromDetail = true;
        vm.$refs.past_order_tab.click();
        vm.$refs.past_order_tab.classList.add('active');
        vm.$refs.current_order_tab.classList.remove('active');
      }
    });
  },

  methods: {
    ...mapActions('orders', ['getCurrentOrders', 'getOrders',]),
    async cancelOrder(orderID) {
      if (confirm('Are you sure you want to cancel order ?')) {
        let result = await orders.orderCancel({order_id:orderID});
        if(result?.data?.status)
        {
          toaster().success(result?.data?.message);
          this.removeOrder(orderID);
          await this.getCurrentOrders();
          await this.getOrders();
          // window.location.reload()
        }else{
          toaster().error(result?.data?.message);
        }
        // clearInterval(this.orderStatusInterval); // clear call request in the all project
        this.setLastOrderId(0);
        // this.showRequestBox = false;
      }
    },
    ...mapMutations('orders', ['setLastOrderId' , 'removeOrder']),
    async changePage(page, e){
      await this.getOrders(page);
      e.target.blur();
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
    goOrderDetail(orderID) {
      this.$router.push({name: 'OrderDetails', params: { id: orderID}});
    },
    async downloadInvoiceBtn(id, e) {
      const buttonTag = e.composedPath().find((el)=>el.classList.contains("invoice-button"));
      const buttonContent = buttonTag.innerHTML;
      try {
        buttonTag.setAttribute('disabled',"");
        buttonTag.innerHTML = `<span style="width: 25px; height: 25px; margin: 0; padding: 0;" class="spinner-border"></span>`;
        const response = await orders.downloadInvoice(id);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const downloadTag = this.$refs.downloadTag;
        downloadTag.href = url;
        downloadTag.download = `Invoice Order #${id}`;
        downloadTag.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }finally {
        buttonTag.removeAttribute('disabled');
        buttonTag.innerHTML = buttonContent;
      }
    },
    goTrackOrders(orderID) {
     let base64Encoded= window.btoa( orderID )
  
      const url = `${peyk.host}track-order/${base64Encoded}`; 
      window.open(url, '_blank');
    },

    
  }
}
</script>

<style scoped>
#peyk_bodynav {
  padding-left: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: white;
}


.nav-tabs-peyk-line {
  align-items: center;
}

.nav-tabs-peyk-line .nav-link-peyk-line {
  margin-bottom: -1px;
  border: none !important;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  background: none;
}
.details-button{
  flex: 1;
  border-radius: 6px;
  padding: 11px 0;
  background: #00B7C2;
  color: #FFFFFF;
  border: none;
  outline: none;
}
.order-report-btn{
  cursor: pointer;
  background-color:#063470 ;
  color: #fff;
}
.order-report-btn:hover{
  background-color: #00204a;
  color: #fff;

}

.nav-tabs-peyk-line .nav-item.show .nav-link-peyk-line,
.nav-tabs-peyk-line .nav-link-peyk-line.active {
  border: none !important;
  border-bottom: 2px solid #21C4F5 !important;
  color: #457C9F;
  outline: none;
  font-size: 25px;
  font-weight: bold;
}

.nav-tabs-peyk-line .nav-link-peyk-line {
  color: #CBCBCB;
  font-size: 25px;
  font-weight: bold;
}

.nav-tabs-peyk-line .nav-link-peyk-line:focus,
.nav-tabs-peyk-line .nav-link-peyk-line:hover, .nav-tabs-peyk-line .nav-link-peyk-line {
  border: none;
  outline: none;

}

@media (max-width: 567px) {
  .nav-tabs-peyk-line .nav-link-peyk-line, .nav-tabs-peyk-line .nav-item.show .nav-link-peyk-line,
  .nav-tabs-peyk-line .nav-link-peyk-line.active {
    font-size: 15px;
  }
}


.add-new-order-button {
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  filter: grayscale(1);
}

.box:hover .add-new-order-button {
  filter: grayscale(0);
  transform: rotate(180deg) scale(1.1);

  transition: all .25s ease-in;
}
</style>
